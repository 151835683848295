import { MenuItem, useMenu, createItem } from '~/api/drupal/useMenu'
import { MainMenuFieldsFragment, MediaImageFragment } from '~/api/graphql/generated'

export interface MainMenuItemTeaser {
  enabled: boolean
  image?: MediaImageFragment
  title: string
  ctaUrl: string
  ctaTitle: string
}
export interface MainMenuItem extends MenuItem {
  cta: {
    url: string
    text: string
  }
}
export type MainMenu = MainMenuItem[]

const checkAndSetActive = (item: MenuItem) => {
  let isActive = item.isActive || false

  if (item.items) {
    for (let i of item.items) {
      isActive = checkAndSetActive(i) || isActive
    }
  }

  item.isActive = isActive
  return isActive
}

export const useMainMenu = async () => {
  const route = useRoute()
  const { data } = await useMenu('main')

  const menu: ComputedRef<MainMenu> = computed(() => {
    return (
      data.value.links?.map((menuItem) => {
        const entity = menuItem.entity as MainMenuFieldsFragment

        const item = {
          ...createItem(menuItem, route.path),
          cta: {
            url: entity.fieldButton?.url?.path || '',
            text: entity.fieldButton?.title || '',
          },
        }

        return {
          ...item,
          isActive: checkAndSetActive(item),
        }
      }) || []
    )
  })

  return { menu }
}
